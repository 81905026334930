import {
  API_URL,
  API_KEY,
  debounce,
  sortByDate,
  DAYS,
  MONTHS,
  formatDate,
} from "../util";
import {
  BORDER_BLUE,
  BORDER_GREEN,
  BORDER_PURPLE,
  BORDER_RED,
  CHART_BLUE,
  CHART_GREEN,
  CHART_PURPLE,
  CHART_RED,
  borderColors,
  chartColors,
  getBorderColor,
  getChartColor,
  renderChartToScreen,
} from "../util/charts";

const SHOW_TYPES = {
  all: "All Bway",
  plays: "Plays Only",
  musicals: "Musicals Only",
};

const TIMESPANS = {
  all: "all",
  year: "year",
  quarter: "quarter",
  month: "month",
  week: "week",
};

const SHOW_COUNT_SETTINGS = {
  separate: "separate",
  combined: "combined",
}

const SALES_LOCATION_SETTINGS = {
  separate: "separate",
  combined: "combined",
}

const convertMsToDays = (ms) => Math.ceil(ms / 1000 / 60 / 60 / 24);
const convertDaysToMs = (days) => days * 24 * 60 * 60 * 1000;

const EARLIEST_DATE = new Date("2021-09-14");
const TIMESPAN_LENGTHS = {
  all: (() => {
    const diffMs = new Date().getTime() - EARLIEST_DATE.getTime();
    return convertMsToDays(diffMs);
  })(),
  year: 365,
  quarter: 90,
  month: 30,
  week: 7,
};

const getSummaryStartDateElement = () =>
  document.querySelector("[summary-start-date]");
const getSummaryEndDateElement = () =>
  document.querySelector("[summary-end-date]");

const getRangeStartDate = () => document.querySelector("[range-start-date]");
const getRangeEndDate = () => document.querySelector("[range-end-date]");
const getRangeShadedRegion = () =>
  document.querySelector(".top__range-window--outside");

const getStartDateElement = () => document.querySelector("[start-date]");
const getEndDateElement = () => document.querySelector("[end-date]");

const getIndividualShowsButton = () =>
  document.querySelector("[individual-shows]");
const getIndividualShowsList = () =>
  document.querySelector("[individual-shows-list]");

const getPerformanceTalliesList = () =>
  document.querySelector("ul.listings__list");

const getShowTypePElement = () => document.querySelector("[show-type]");
const getAverageCostElement = () => document.querySelector("[average-cost]");
const getAverageDiscountElement = () =>
  document.querySelector("[average-discount]");

const getPriceRangeElement = () => document.getElementById("price-range");
const getDiscountRangeElement = () => document.getElementById("discount-range");
const getPercentCapacityElement = () =>
  document.getElementById("percent-capacity");
const getPercentRevenueElement = () =>
  document.getElementById("percent-revenue");

const getPriceRangeLowElement = () =>
  document.querySelector("[price-range-low]");
const getPriceRangeHighElement = () =>
  document.querySelector("[price-range-high]");
const getDiscountRangeLowElement = () =>
  document.querySelector("[discount-range-low]");
const getDiscountRangeHighElement = () =>
  document.querySelector("[discount-range-high]");
const getPercentCapacityLowElement = () =>
  document.querySelector("[percent-capacity-low]");
const getPercentCapacityHighElement = () =>
  document.querySelector("[percent-capacity-high]");
const getPercentRevenueLowElement = () =>
  document.querySelector("[percent-revenue-low]");
const getPercentRevenueHighElement = () =>
  document.querySelector("[percent-revenue-high]");

const getDateRangeElement = () => document.getElementById("date-range");
const getTimeSpanElement = () => document.getElementById("timespan");
const getShowCountsSliderElement = () => document.getElementById("show-counts-toggle");
const getSalesLocationSliderElement = () => document.getElementById("sales-location-toggle");
const getShowTypeElement = () => document.getElementById("showtype");
const getSelectionElements = () =>
  document.querySelectorAll('input[type="checkbox"][name="selections"]');

const getChartsElement = () => document.querySelector("div.charts");
const getPdfExportButton = () => document.querySelector("[pdf-export]");
const getCsvExportButton = () => document.querySelector("[csv-export]");

const formatSummaryDate = (date) => {
  const dayOfWeek = DAYS[date.getDay()];
  const month = MONTHS[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${dayOfWeek}. ${month}. ${day}, ${year}`;
};

const setAverageCost = (priceRanges) => {
  const total = priceRanges.reduce(
    (total, range) => total + (range?.high ?? 0) + (range?.low ?? 0),
    0
  );
  const average = total / (priceRanges.length * 2);
  getAverageCostElement().textContent = average.toFixed(2);
};

const setAverageDiscount = (discounts) => {
  const total = discounts.reduce((total, discount) => total + discount, 0);
  const average = total / discounts.length;
  getAverageDiscountElement().textContent = average.toFixed(1);
};

const now = new Date();
let timespan = TIMESPANS.month;
let end = new Date(now);
let start = new Date(
  new Date(now).setDate(now.getDate() - TIMESPAN_LENGTHS.week * 2)
);

let isIndividualShows = false;
const includedShowIds = new Set();
let showType = "all";

let minPrice = 0;
let maxPrice = 290;
let minDiscount = 0;
let maxDiscount = 100;
let minPercentCapacity = 0;
let maxPercentCapacity = 100;
let minPercentRevenue = 0;
let maxPercentRevenue = 100;

let showCountSettings = SHOW_COUNT_SETTINGS.combined
let salesLocationsettings = SALES_LOCATION_SETTINGS.separate

const handleIndividualShows = () => {
  if (isIndividualShows) {
    getIndividualShowsButton().textContent = "Get Individual Shows";
    getIndividualShowsList().style.display = "none";
    includedShowIds.clear();
    fetchDataAndFillCharts();
  } else {
    getIndividualShowsButton().textContent = "Close";
    getIndividualShowsList().style.display = "flex";
    getShowNames();
  }
  isIndividualShows = !isIndividualShows;
};

const getHotelPrices = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/hotel-prices/tallies/`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const prices = result.data.prices;
  const sortedDates = sortByDate(Object.keys(prices));

  const labels = sortedDates.map((date) => formatDate(date));

  const data = {
    labels,
    datasets: [
      {
        label: "Targeted dates",
        data: sortedDates.map(
          (date) =>
            prices[date][
            Object.keys(prices[date]).reduce((key, d) =>
              new Date(key) < new Date(d) ? key : d
            )
            ]
        ),
        backgroundColor: borderColors[0],
        borderColor: borderColors[0],
        borderWidth: 1,
      },
      {
        label: "Previous year comparison",
        data: sortedDates.map(
          (date) =>
            prices[date][
            Object.keys(prices[date]).reduce((key, d) =>
              new Date(key) > new Date(d) ? key : d
            )
            ]
        ),
        backgroundColor: chartColors[0],
        borderColor: chartColors[0],
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Average Hotel Prices in Times Square",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const key = sortedDates[context.dataIndex];
              const date = Object.keys(prices[key])[context.datasetIndex];
              return date;
            },
            label: (context) => {
              return `$${parseFloat(context.formattedValue).toFixed(2)}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("hotel-price-chart", config);
});

let prevCall;
let surveyData;
const getSurveyData = () => {
  if (
    surveyData &&
    prevCall[0] === now.getTime() &&
    prevCall[1] === start.getTime() &&
    prevCall[2] === end.getTime()
  ) {
    return surveyData;
  }

  prevCall = [now.getTime(), start.getTime(), end.getTime()];

  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/surveys/tallies/by-date`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  surveyData = fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  return surveyData;
};

const getFamilyShoppers = debounce(async () => {
  const result = await getSurveyData();

  const tallies = result.data.surveys;

  const totals = Object.values(tallies).reduce(
    (acc, data) => {
      acc["Family Shopper"] += data["Family Shopper"];
      acc["Non Family Shopper"] += data["Non Family Shopper"];
      return acc;
    },
    { "Family Shopper": 0, "Non Family Shopper": 0 }
  );
  const total = totals["Family Shopper"] + totals["Non Family Shopper"];
  const data = {
    labels: Object.keys(totals),
    datasets: [
      {
        data: Object.values(totals).map((value) => (value / total) * 100),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Survey: Shopping for a Family with Young Children",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
    },
  };

  // Bar/Line chart format
  // const sets = ["First Time", "Returning"];
  // const sortedDates = sortByDate(Object.keys(tallies));

  // const labels = sortedDates.map((date) => formatDate(date));
  // const data = {
  //   labels,
  //   datasets: sets.map((label, i) => {
  //     return {
  //       label,
  //       data: sortedDates.map((date) => tallies[date][label]),
  //       backgroundColor: getChartColor(i),
  //       borderColor: getBorderColor(i),
  //       borderWidth: 1,
  //     };
  //   }),
  // };
  // const config = {
  //   type: "bar",
  //   data,
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Survey: First Time Theatergoers",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           font: {
  //             weight: labels.map((label) =>
  //               [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
  //             ),
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  renderChartToScreen("survey-family-chart", config);
});

const getSurveyFirstTimers = debounce(async () => {
  const result = await getSurveyData();

  const tallies = result.data.surveys;

  const totals = Object.values(tallies).reduce(
    (acc, data) => {
      acc["First Time"] += data["First Time"];
      acc["Returning"] += data["Returning"];
      return acc;
    },
    { "First Time": 0, Returning: 0 }
  );
  const total = totals["First Time"] + totals["Returning"];
  const data = {
    labels: Object.keys(totals),
    datasets: [
      {
        data: Object.values(totals).map((value) => (value / total) * 100),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Survey: First Time Theatergoers",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
    },
  };

  // Bar/Line chart format
  // const sets = ["First Time", "Returning"];
  // const sortedDates = sortByDate(Object.keys(tallies));

  // const labels = sortedDates.map((date) => formatDate(date));
  // const data = {
  //   labels,
  //   datasets: sets.map((label, i) => {
  //     return {
  //       label,
  //       data: sortedDates.map((date) => tallies[date][label]),
  //       backgroundColor: getChartColor(i),
  //       borderColor: getBorderColor(i),
  //       borderWidth: 1,
  //     };
  //   }),
  // };
  // const config = {
  //   type: "bar",
  //   data,
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Survey: First Time Theatergoers",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           font: {
  //             weight: labels.map((label) =>
  //               [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
  //             ),
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  renderChartToScreen("survey-noob-chart", config);
});

const getSurveyResidences = debounce(async () => {
  const result = await getSurveyData();

  const tallies = result.data.surveys;

  const totals = Object.values(tallies).reduce(
    (acc, data) => {
      acc["NYC"] += data["NYC"];
      acc["NYC Suburbs"] += data["NYC Suburbs"];
      acc["Other Country"] += data["Other Country"];
      acc["Other U.S."] += data["Other U.S."];

      return acc;
    },
    { NYC: 0, "NYC Suburbs": 0, "Other Country": 0, "Other U.S.": 0 }
  );
  const total =
    totals["NYC"] +
    totals["NYC Suburbs"] +
    totals["Other Country"] +
    totals["Other U.S."];
  const data = {
    labels: Object.keys(totals),
    datasets: [
      {
        data: Object.values(totals).map((value) => (value / total) * 100),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Survey: Customer Residence",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
    },
  };

  // For Bar/Line charting
  // const sets = ["NYC", "NYC Suburbs", "Other Country", "Other U.S."];
  // const sortedDates = sortByDate(Object.keys(tallies));

  // const labels = sortedDates.map((date) => formatDate(date));
  // const data = {
  //   labels,
  //   datasets: sets.map((label, i) => {
  //     return {
  //       label,
  //       data: sortedDates.map((date) => tallies[date][label]),
  //       backgroundColor: getChartColor(i),
  //       borderColor: getBorderColor(i),
  //       borderWidth: 1,
  //     };
  //   }),
  // };
  // const config = {
  //   type: "bar",
  //   data,
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Survey: Customer Residence",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           font: {
  //             weight: labels.map((label) =>
  //               [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
  //             ),
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  renderChartToScreen("survey-residence-chart", config);
});

const getInternationalDemographicsData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/demographics/tallies/international`);
  url.searchParams.set("threshold", 8);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const demographics = result.data.demographics;

  const countryTallies = Object.keys(demographics).reduce((acc, date) => {
    Object.keys(demographics[date]).forEach((country) => {
      acc[country] = 0;
    });
    return acc;
  }, {});

  const totals = Object.values(demographics).reduce((acc, data) => {
    Object.keys(data).forEach((country) => (acc[country] += data[country]));
    return acc;
  }, countryTallies);

  const total = Object.values(totals).reduce((tot, num) => tot + num, 0);
  const data = {
    labels: Object.keys(totals),
    datasets: [
      {
        data: Object.values(totals).map((value) => (value / total) * 100),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Residence of International visitors by country",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
    },
  };

  // Bar/Line chart format
  // const countries = Object.keys(demographics).reduce((set, date) => {
  //   Object.keys(demographics[date]).forEach((country) => {
  //     set.add(country);
  //   });
  //   return set;
  // }, new Set());

  // const sortedDates = sortByDate(Object.keys(demographics));

  // const labels = sortedDates.map((date) => formatDate(date));
  // const data = {
  //   labels,
  //   datasets: Array.from(countries).map((label, i) => {
  //     return {
  //       label,
  //       data: sortedDates.map((date) => demographics[date][label] ?? 0),
  //       backgroundColor: getChartColor(i),
  //       borderColor: getBorderColor(i),
  //       borderWidth: 1,
  //     };
  //   }),
  // };
  // const config = {
  //   type: "line",
  //   data,
  //   options: {
  //     tension: 0.3,
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Residence of International visitors by country",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           font: {
  //             weight: labels.map((label) =>
  //               [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
  //             ),
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  renderChartToScreen("international-demographics-chart", config);
});

const getDomesticDemographicsData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/demographics/tallies/domestic`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const demographics = result.data.demographics;

  const stateTallies = Object.keys(demographics).reduce((acc, date) => {
    Object.keys(demographics[date]).forEach((state) => {
      acc[state] = 0;
    });
    return acc;
  }, {});

  const totals = Object.values(demographics).reduce((acc, data) => {
    Object.keys(data).forEach((state) => (acc[state] += data[state]));
    return acc;
  }, stateTallies);

  const total = Object.values(totals).reduce((tot, num) => tot + num, 0);
  const data = {
    labels: Object.keys(totals),
    datasets: [
      {
        data: Object.values(totals).map((value) => (value / total) * 100),
        backgroundColor: chartColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "pie",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Residence of Domestic visitors by state",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
    },
  };

  // Bar/Line chart format
  // const states = Object.keys(demographics).reduce((set, date) => {
  //   Object.keys(demographics[date]).forEach((state) => {
  //     set.add(state);
  //   });
  //   return set;
  // }, new Set());

  // const sortedDates = sortByDate(Object.keys(demographics));

  // const labels = sortedDates.map((date) => formatDate(date));
  // const data = {
  //   labels,
  //   datasets: Array.from(states).map((label, i) => {
  //     return {
  //       label,
  //       data: sortedDates.map((date) => demographics[date][label] ?? 0),
  //       backgroundColor: getChartColor(i),
  //       borderColor: getBorderColor(i),
  //       borderWidth: 1,
  //     };
  //   }),
  // };
  // const config = {
  //   type: "line",
  //   data,
  //   options: {
  //     tension: 0.3,
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Residence of Domestic visitors by state",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           font: {
  //             weight: labels.map((label) =>
  //               [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
  //             ),
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  renderChartToScreen("domestic-demographics-chart", config);
});

const getShowPriceRanges = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/tallies/price-ranges`);
  url.searchParams.set("minPrice", minPrice);
  url.searchParams.set("maxPrice", maxPrice);
  url.searchParams.set("minDiscount", minDiscount);
  url.searchParams.set("maxDiscount", maxDiscount);
  url.searchParams.set("type", showType);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  includedShowIds.forEach((id) => url.searchParams.append("productionIds", id));

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const priceRanges = result.data.priceRanges;

  const allPriceRange = Object.values(priceRanges).map((set) => set.all);
  setAverageCost(allPriceRange);

  const sortedDates = sortByDate(Object.keys(priceRanges));

  const labels = sortedDates.map((date) => formatDate(date));
  const data = {
    labels, //: sortedDates.map(() => ""),
    datasets: [
      // Averages
      ...["broadway", 'off-broadway'].concat(Array.from(includedShowIds)).map((label, i) => {
        return {
          type: "line",
          label: i > 2
            ? `${document.getElementById(label)?.textContent} average`
            : `Average ${label[0].toUpperCase() + label.slice(1)} Price Listed`,
          data: sortedDates.map((date) => {
            const info = priceRanges[date][label];
            return info?.averageListed || null;
          }),
          backgroundColor: getChartColor(i),
          borderColor: getChartColor(i),
          borderWidth: 1,
        };
      }),

      // Paid
      // ...["broadway", 'off-broadway'].concat(Array.from(includedShowIds)).map((label, i) => {
      //   return {
      {
        type: "line",
        // label: i > 2
        //   ? `${document.getElementById(label)?.textContent} average`
        //   : `Average ${label[0].toUpperCase() + label.slice(1)} Price Paid`,
        label: "Average Price Paid",
        data: sortedDates.map((date) => {
          // const info = priceRanges[date][label];
          const info = priceRanges[date]['broadway'];
          return info?.averagePaid || null;
        }),
        backgroundColor: "#777",
        borderColor: "#777",
        borderWidth: 1,
      },
      //   };
      // }),

      // Ranges
      ...[
        "all",
        // "broadway",
        // "off-broadway",
      ].concat(Array.from(includedShowIds)).map((label, i) => {
        return {
          type: "bar",
          label: !!i
            // i > 2
            ? `${document.getElementById(label)?.textContent} range`
            : `Range for ${label[0].toUpperCase() + label.slice(1)}`,
          data: sortedDates.map((date) => {
            const info = priceRanges[date][label];
            return [info?.low ?? 0, info?.high ?? 0];
          }),
          backgroundColor: !i ? '#ccc' : getChartColor(i - 1),
          borderColor: !i ? '#ccc' : getChartColor(i - 1),
          borderWidth: 1,
        };
      }),

    ],
  };
  const config = {
    type: "bar",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Prices at TKTS Over Time",
          position: "left",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const date = sortedDates[context.dataIndex];
              return formatDate(date);
            },
            label: (context) => {
              if (Array.isArray(context.raw)) {
                const [low, high] = context.raw;
                return `$${parseFloat(low).toFixed(2)} - ${parseFloat(
                  high
                ).toFixed(2)}`;
              }

              return `$${parseFloat(context.formattedValue).toFixed(2)}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("price-range-chart", config);
});

const getShowCounts = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/tallies/show-counts`);
  // url.searchParams.set("minPrice", minPrice);
  // url.searchParams.set("maxPrice", maxPrice);
  // url.searchParams.set("minDiscount", minDiscount);
  // url.searchParams.set("maxDiscount", maxDiscount);
  // url.searchParams.set("type", showType);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  // includedShowIds.forEach((id) => url.searchParams.append("productionIds", id));

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const showCounts = result.data.showCounts;

  const sortedDates = sortByDate(Object.keys(showCounts));

  const datasets = []
  if (showCountSettings === SHOW_COUNT_SETTINGS.combined) {
    datasets.push('all')
  } else {
    datasets.push('broadway')
    datasets.push('off-broadway')
  }

  const colors1 = [
    BORDER_RED,
    BORDER_GREEN,
    CHART_RED,
    CHART_GREEN,
  ]

  const colors2 = [
    BORDER_BLUE,
    CHART_BLUE,
  ]

  const colors = showCountSettings === SHOW_COUNT_SETTINGS.combined ? colors2 : colors1
  const colorSkip = showCountSettings === SHOW_COUNT_SETTINGS.combined ? 1 : 2

  const labels = sortedDates.map((date) => formatDate(date));
  const data = {
    labels,
    datasets: [
      ...datasets.map(function(label, i) {
        return {
          tension: 0.3,
          label: `${label[0].toUpperCase()}${label.slice(1)} Shows`,
          data: sortedDates.map((date) => showCounts[date][label]["shows"] || null),
          backgroundColor: colors[i],
          borderColor: colors[i],
          borderWidth: 1,
        }
      }),
      ...datasets.map(function(label, i) {
        return {
          tension: 0.3,
          label: `${label[0].toUpperCase()}${label.slice(1)} Performances`,
          data: sortedDates.map((date) => showCounts[date][label]["performances"] || null),
          backgroundColor: colors[i + colorSkip],
          borderColor: colors[i + colorSkip],
          borderWidth: 1,
        }
      })
    ],
  };
  const config = {
    type: "line",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Count of Broadway shows sold by day",
          position: "left",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const date = sortedDates[context.dataIndex];
              return formatDate(date);
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("show-count-chart", config);
});

const getShowAverageDiscounts = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/tallies/average-discounts`);
  url.searchParams.set("minPrice", minPrice);
  url.searchParams.set("maxPrice", maxPrice);
  url.searchParams.set("minDiscount", minDiscount);
  url.searchParams.set("maxDiscount", maxDiscount);
  url.searchParams.set("type", showType);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  includedShowIds.forEach((id) => url.searchParams.append("productionIds", id));

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const discounts = result.data.discounts;

  setAverageDiscount(Object.values(discounts).map((set) => set.all));

  const sortedDates = sortByDate(Object.keys(discounts));

  const labels = sortedDates.map((date) => formatDate(date));
  const data = {
    labels, //: sortedDates.map(() => ""),
    datasets: ["All"].concat(Array.from(includedShowIds)).map((label, i) => {
      return {
        fill: true,
        label: i ? document.getElementById(label)?.textContent : label,
        data: sortedDates.map(
          (date) => discounts[date][label.toLowerCase()] || null
        ),
        backgroundColor: getChartColor(i - 1),
        borderColor: getBorderColor(i - 1),
        borderWidth: 1,
      };
    }),
  };
  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Average TKTS Discount over Time",
          position: "left",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const date = sortedDates[context.dataIndex];
              return formatDate(date);
            },
            label: (context) => {
              return `${parseFloat(context.formattedValue).toFixed(1)}%`;
            },
          },
        },
      },
      scales: {
        y: {
          min: 20,
          max: 50,
          reverse: true,
        },
        x: {
          ticks: {
            font: {
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("average-discount-chart", config);
});

const getGrossesCompData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/tallies/percent-perfs-at-tkts`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const percentages = result.data.percentages;

  const sortedLabels = Object.keys(percentages).sort((a, b) => {
    [a] = a.split(" ");
    [b] = b.split(" ");
    const aDate = new Date(a).getTime();
    const bDate = new Date(b).getTime();
    return aDate < bDate ? -1 : 1;
  });

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: "Weekly Percentages",
        data: sortedLabels.map((date) => percentages[date]),
        backgroundColor: chartColors[0],
        borderColor: borderColors[0],
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Percentage of ALL Scheduled Broadway Performances that Discounted at TKTS",
          position: "left",
        },
      },
    },
  };

  renderChartToScreen("grosses-comp-chart", config);
});

const getBwaySalesPercentageData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/sales/tallies/tkts-bway-sales-percentage`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const percentages = result.data.percentages;

  const sortedLabels = Object.keys(percentages).sort((a, b) => {
    [a] = a.split(" ");
    [b] = b.split(" ");
    const aDate = new Date(a).getTime();
    const bDate = new Date(b).getTime();
    return aDate < bDate ? -1 : 1;
  });

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: "Weekly Percentages",
        data: sortedLabels.map((date) => percentages[date]),
        backgroundColor: chartColors[0],
        borderColor: borderColors[0],
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Percentage of Broadway Sales at TKTS",
          position: "left",
        },
      },
    },
  };

  renderChartToScreen("bway-sales-percentage-chart", config);
});

const getSalesByCategoryData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/sales/tallies/tickets-by-category`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const sales = result.data.sales;

  const sortedLabels = Object.keys(sales).sort((a, b) => {
    [a] = a.split(" ");
    [b] = b.split(" ");
    const aDate = new Date(a).getTime();
    const bDate = new Date(b).getTime();
    return aDate < bDate ? -1 : 1;
  });

  const datasetLabels = Object.keys(sales[sortedLabels[0]]);

  const data = {
    labels: sortedLabels,
    datasets: datasetLabels.map(function(label, i) {
      return {
        label,
        data: sortedLabels.map((date) => sales[date][label]),
        backgroundColor: chartColors[i],
        borderColor: borderColors[i],
        borderWidth: 1,
      };
    }),
  };
  const config = {
    type: "bar",
    data,
    options: {
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Tickets Sold by Category",
        },
      },
    },
  };

  renderChartToScreen("tickets-by-category-chart", config);
});

const getSalesData = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/sales/tallies/tickets`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  url.searchParams.append("location", "duffy")
  url.searchParams.append("location", "lincoln center")

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const sales = result.data.sales;

  const sortedLabels = Object.keys(sales).sort((a, b) => {
    [a] = a.split(" ");
    [b] = b.split(" ");
    const aDate = new Date(a).getTime();
    const bDate = new Date(b).getTime();
    return aDate < bDate ? -1 : 1;
  });

  const formattedDates = sortedLabels.map((date) => formatDate(date));

  const datasets = []
  if (salesLocationsettings === SALES_LOCATION_SETTINGS.separate) {
    datasets.push(
      {
        label: "Duffy Tickets Sold",
        data: sortedLabels.map((date) => sales[date]["duffy"]),
        backgroundColor: CHART_BLUE,
        borderColor: BORDER_BLUE,
        borderWidth: 1,
        tension: 0.3,
      },
      {
        label: "Lincoln Center Tickets Sold",
        data: sortedLabels.map((date) => sales[date]["lincoln center"]),
        backgroundColor: CHART_PURPLE,
        borderColor: BORDER_PURPLE,
        borderWidth: 1,
        tension: 0.3,
      },
    )
  } else {
    datasets.push({
      label: "Total Sales Per Day",
      data: sortedLabels.map((date) => sales[date]["duffy"] + sales[date]["lincoln center"]),
      backgroundColor: CHART_BLUE,
      borderColor: BORDER_BLUE,
      borderWidth: 1,
      tension: 0.3,
    })
  }

  const data = {
    labels: formattedDates,
    datasets,
  };
  const config = {
    type: "line",
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Tickets sold at TKTS",
          position: "left",
        },
      },
      scales: {
        y: {
          ticks: {
            callback: (value) => {
              return `${value / 1000}k`;
            },
          },
        },
        x: {
          ticks: {
            font: {
              weight: formattedDates.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("total-sales-chart", config);
});

const getShowNames = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/names`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  url.searchParams.set("type", showType);
  url.searchParams.set("minPrice", minPrice);
  url.searchParams.set("maxPrice", maxPrice);
  url.searchParams.set("minDiscount", minDiscount);
  url.searchParams.set("maxDiscount", maxDiscount);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const shows = result.data.shows;

  const showList = getIndividualShowsList();
  showList.innerHTML = "";

  Object.keys(shows).forEach((id) => {
    const name = shows[id];

    const li = document.createElement("li");
    li.textContent = name;
    li.id = id;
    li.classList.add("ranges__show-name");
    if (includedShowIds.has(id)) li.classList.add("selected");

    li.addEventListener("click", (e) => {
      if (includedShowIds.has(e.target.id)) {
        includedShowIds.delete(e.target.id);
        e.target.classList.remove("selected");
      } else {
        includedShowIds.add(e.target.id);
        e.target.classList.add("selected");
      }
      EVERY_TIME_HANDLERS.forEach((handler) => handler());
    });

    showList.appendChild(li);
  });

  includedShowIds.forEach((id) => {
    if (document.getElementById(id)) return;
    includedShowIds.delete(id);
  });
}, 200);

const getPerformanceTallies = debounce(async () => {
  const days = convertMsToDays(now.getTime() - start.getTime()) + 1;
  const skip = convertMsToDays(now.getTime() - end.getTime());

  const url = new URL(`${API_URL}/shows/tallies/performances`);
  url.searchParams.set("days", days);
  url.searchParams.set("skip", skip);
  url.searchParams.set("type", showType);
  url.searchParams.set("minPrice", minPrice);
  url.searchParams.set("maxPrice", maxPrice);
  url.searchParams.set("minDiscount", minDiscount);
  url.searchParams.set("maxDiscount", maxDiscount);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const performances = result.data.performances;

  const performanceTalliesList = getPerformanceTalliesList();
  performanceTalliesList.innerHTML = "";

  Object.entries(performances)
    .sort(([_a, aCount], [_b, bCount]) => {
      return bCount - aCount;
    })
    .forEach(([name, count]) => {
      const li = document.createElement("li");
      li.classList.add("listings__list-item");

      const span = document.createElement("span");
      span.classList.add("listings__name");
      span.textContent = name;

      li.appendChild(span);
      li.append(count);

      performanceTalliesList.appendChild(li);
    });

  includedShowIds.forEach((id) => {
    if (document.getElementById(id)) return;
    includedShowIds.delete(id);
  });
}, 200);

const inclusions = new Set();
const INCLUSION_HANDLERS = {
  hotels: getHotelPrices,
  residence: getSurveyResidences,
  noob: getSurveyFirstTimers,
  family: getFamilyShoppers,
  domestic: getDomesticDemographicsData,
  international: getInternationalDemographicsData,
};

const EVERY_TIME_HANDLERS = [
  getShowPriceRanges,
  getShowCounts,
  getShowAverageDiscounts,
  getSalesData,
  getGrossesCompData,
  getBwaySalesPercentageData,
  getSalesByCategoryData,
  getPerformanceTallies,
];

const fetchDataAndFillCharts = () => {
  if (isIndividualShows) getShowNames();
  debounce(
    () =>
      Array.from(getChartsElement().querySelector("[chart]")).forEach((div) => {
        div.classList.remove("active");
      }),
    250
  )();
  EVERY_TIME_HANDLERS.forEach((handler) => handler());
  inclusions.forEach((inclusion) => INCLUSION_HANDLERS[inclusion]?.());
};

const handleInclusions = (e) => {
  if (e.target.checked) {
    inclusions.add(e.target.value);
  } else {
    inclusions.delete(e.target.value);
  }
  debounce(
    () =>
      Array.from(getChartsElement().querySelector("[chart]")).forEach((div) => {
        div.innerHTML = "";
      }),
    250
  )();
  fetchDataAndFillCharts();
};

const setTimespan = (newTimespan) => {
  timespan = newTimespan;
  const earliest = new Date(
    new Date(now).setDate(now.getDate() - TIMESPAN_LENGTHS[timespan])
  );

  if (start.getTime() < earliest.getTime()) start = new Date(earliest);
  if (end.getTime() < start.getTime())
    end = new Date(start.getTime() + convertDaysToMs(1));

  getDateRangeElement().dispatchEvent(
    new CustomEvent("set-labels", {
      detail: {
        low: start.toLocaleDateString(),
        high: end.toLocaleDateString(),
      },
    })
  );

  getRangeStartDate().textContent = earliest.toLocaleDateString();
  //  getRangeEndDate().textContent =

  getSummaryStartDateElement().textContent = formatSummaryDate(start);
  getSummaryEndDateElement().textContent = formatSummaryDate(end);

  const rangeMs = now.getTime() - earliest.getTime();
  const lowRatio = rangeMs - (now.getTime() - start.getTime());
  const low = (lowRatio / rangeMs) * 100;
  const highRatio = rangeMs - (now.getTime() - end.getTime());
  const high = (highRatio / rangeMs) * 100;

  getDateRangeElement().dispatchEvent(
    new CustomEvent("set-value", { detail: { high, low } })
  );
};

const setSalesLocationSettings = (value) => {
  salesLocationsettings = value;
  getSalesData()
}

const setShowCountsSettings = (value) => {
  showCountSettings = value;
  getShowCounts()
}

const setDateRange = async (low, high) => {
  const lowDays = Math.round((low * TIMESPAN_LENGTHS[timespan]) / 100);
  const highDays = Math.round((high * TIMESPAN_LENGTHS[timespan]) / 100);

  end = new Date(
    new Date(now).setDate(
      now.getDate() - (TIMESPAN_LENGTHS[timespan] - highDays)
    )
  );
  start = new Date(
    new Date(now).setDate(
      now.getDate() - (TIMESPAN_LENGTHS[timespan] - lowDays)
    )
  );

  getRangeShadedRegion().style.setProperty("--high", `${high}%`);
  getRangeShadedRegion().style.setProperty("--low", `${low}%`);

  getDateRangeElement().dispatchEvent(
    new CustomEvent("set-labels", {
      detail: {
        low: start.toLocaleDateString(),
        high: end.toLocaleDateString(),
      },
    })
  );

  getSummaryStartDateElement().textContent = formatSummaryDate(start);
  getSummaryEndDateElement().textContent = formatSummaryDate(end);

  fetchDataAndFillCharts();
};

const handleShowTypeChange = (type) => {
  showType = type;
  getShowTypePElement().textContent = SHOW_TYPES[type];
  fetchDataAndFillCharts();
};

const setPriceRange = (low, high) => {
  const ratio = 290 / 100;

  minPrice = low * ratio;
  maxPrice = high * ratio;

  getPriceRangeLowElement().textContent = `$${minPrice.toFixed(2)}`;
  getPriceRangeHighElement().textContent = `$${maxPrice.toFixed(2)}`;

  fetchDataAndFillCharts();
};

const setDiscountRange = (low, high) => {
  const ratio = 50 / 100;

  minDiscount = low * ratio;
  maxDiscount = high * ratio;

  getDiscountRangeLowElement().textContent = `${minDiscount.toFixed(1)}%`;
  getDiscountRangeHighElement().textContent = `${maxDiscount.toFixed(1)}%`;

  fetchDataAndFillCharts();
};

const setPercentCapacity = (low, high) => {
  minPercentCapacity = low;
  maxPercentCapacity = high;

  getPercentCapacityLowElement().textContent = `${minPercentCapacity}%`;
  getPercentCapacityHighElement().textContent = `${maxPercentCapacity}%`;

  // fetchDataAndFillCharts();
};

const setPercentRevenue = (low, high) => {
  minPercentRevenue = low;
  maxPercentRevenue = high;

  getPercentRevenueLowElement().textContent = `${minPercentRevenue}%`;
  getPercentRevenueHighElement().textContent = `${maxPercentRevenue}%`;

  // fetchDataAndFillCharts();
};

const reportStateEngine = () => {
  fetchDataAndFillCharts();

  getDateRangeElement().addEventListener("range-change", (e) =>
    setDateRange(e.detail.low, e.detail.high)
  );

  getTimeSpanElement().addEventListener("slider-change", (e) =>
    setTimespan(e.detail.value)
  );

  getShowCountsSliderElement().addEventListener("slider-change", (e) =>
    setShowCountsSettings(e.detail.value)
  );

  getSalesLocationSliderElement().addEventListener("slider-change", (e) =>
    setSalesLocationSettings(e.detail.value)
  );

  getShowTypeElement().addEventListener("slider-change", (e) =>
    handleShowTypeChange(e.detail.value)
  );

  getSelectionElements().forEach((input) => {
    input.addEventListener("input", handleInclusions);
  });

  getIndividualShowsButton().addEventListener("click", handleIndividualShows);

  getPriceRangeElement().addEventListener("range-change", (e) =>
    setPriceRange(e.detail.low, e.detail.high)
  );
  getDiscountRangeElement().addEventListener("range-change", (e) =>
    setDiscountRange(e.detail.low, e.detail.high)
  );
  // getPercentCapacityElement().addEventListener("range-change", (e) =>
  //   setPercentCapacity(e.detail.low, e.detail.high)
  // );
  // getPercentRevenueElement().addEventListener("range-change", (e) =>
  //   setPercentRevenue(e.detail.low, e.detail.high)
  // );
};

const exportCsv = () => {
  const url = new URL(`${window.location.href}.csv`);
  url.searchParams.set("minPrice", minPrice);
  url.searchParams.set("maxPrice", maxPrice);
  url.searchParams.set("minDiscount", minDiscount);
  url.searchParams.set("maxDiscount", maxDiscount);
  url.searchParams.set("type", showType);
  url.searchParams.set("start", start.toISOString());
  url.searchParams.set("end", end.toISOString());
  includedShowIds.forEach((id) =>
    url.searchParams.append("productionIds[]", id)
  );
  inclusions.forEach((inclusion) =>
    url.searchParams.append("inclusions[]", inclusion)
  );
  window.location.href = url.href;
};

document.addEventListener("turbolinks:load", () => {
  if (!document.getElementById("report")) return;

  reportStateEngine();

  getPdfExportButton().addEventListener("click", () => print());
  getCsvExportButton().addEventListener("click", () => exportCsv());
});
