import { renderChartToScreen } from "../util/charts";
import { API_URL, sortByDate, formatDate, API_KEY } from "../util";
import ChartDataLabels from "chartjs-plugin-datalabels";

const COLORS = ["0, 0, 0", "255, 0, 0", "0, 84, 219"];

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll("[primary-show-pod]").forEach(async (pod) => {
    buildShowPricesChart(pod);
    buildSalesChart(pod);

    pod.querySelectorAll(".radio-slider").forEach(function(slider) {
      slider.addEventListener("slider-change", function(event) {
        switch (event.detail.value) {
          case "3-months":
            buildSalesChart(pod, 90)
            break
          case "all":
            buildSalesChart(pod, 99999)
            break
        }
      })
    })
  });

  async function buildSalesChart(pod, days = 90) {
    const { productionId, productionName } = pod.dataset;
    const url = new URL(`${API_URL}/sales/tallies/primary-show`);
    url.searchParams.set("productionId", productionId);

    const isHistorical = !!pod.querySelector('[data-is-historical="true"]')
    if (isHistorical) {
      days = 99999
    }
    url.searchParams.set("days", days);

    const result = await fetch(url, {
      headers: { authorization: `Bearer ${API_KEY}` },
    }).then((res) => res.json());

    const { sales, percentages } = result.data;

    const labels = [];
    const sortedDates = sortByDate(
      Object.keys(sales).map((date) => date.split("-").join("/"))
    ).map((date) => {
      labels.push(formatDate(date));
      return date.split("/").join("-");
    });

    const data = {
      labels,
      datasets: [
        ...["all", "competition", "show"].map(function(key, i) {
          const labels = {
            all: "All Shows at TKTS Average",
            competition: "The Competition Average",
            show: `${productionName} Total`,
          };
          return {
            type: "line",
            label: labels[key],
            data: sortedDates.map((date) => {
              // convert 0 to null
              return sales[date][key] || null;
            }),
            backgroundColor: `rgb(${COLORS[i]})`,
            borderColor: `rgb(${COLORS[i]})`,
            borderWidth: 1,
            datalabels: {
              align: "top",
              font: {
                family: "Verdana",
              },
              formatter: function(value) {
                return key === "show" && days <= 90 ? Math.round(value) : "";
              },
            },
          };
        }),
      ],
    };

    const config = {
      plugins: [ChartDataLabels],
      data,
      options: {
        tension: 0.3,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
            font: { family: "Verdana" },
          },
          title: {
            display: true,
            text: "TKTS Sales",
            position: "top",
            font: { family: "Verdana" },
          },
          tooltip: {
            font: { family: "Verdana" },
            callbacks: {
              title: ([context]) => {
                const date = sortedDates[context.dataIndex];
                return formatDate(date.split("-").join("/"));
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              font: { family: "Verdana" },
            },
          },
          x: {
            ticks: {
              font: {
                family: "Verdana",
                weight: labels.map((label) =>
                  [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
                ),
              },
            },
          },
        },
      },
    };

    renderChartToScreen(`primary-show-pod-sales-chart-${productionId}`, config);

    const percentageData = {
      labels,
      datasets: [
        {
          type: "line",
          label: "Percentage",
          data: sortedDates.map((date) => {
            // convert 0 to null
            return percentages[date] || null;
          }),
          backgroundColor: `rgb(${COLORS[0]})`,
          borderColor: `rgb(${COLORS[0]})`,
          borderWidth: 1,
          datalabels: {
            align: "top",
            font: {
              family: "Verdana",
            },
            formatter: function(value) {
              return value.toFixed(1) + "%";
            },
          },
        },
      ],
    }
    const percentageConfig = {
      ...config,
      options: {
        ...config.options,
        plugins: {
          ...config.options.plugins,
          title: {
            display: true,
            text: "% of week's tickets sold at TKTS",
            position: "top",
            font: { family: "Verdana" },
          }
        },
      },
      data: percentageData,
    }

    renderChartToScreen(`primary-show-pod-percentage-chart-${productionId}`, percentageConfig);
  };

  async function buildShowPricesChart(pod) {
    const { productionId, productionName } = pod.dataset;

    const url = new URL(`${API_URL}/shows/tallies/primary-show-price-ranges`);
    url.searchParams.set("days", 7);
    url.searchParams.set("productionId", productionId);

    const result = await fetch(url, {
      headers: { authorization: `Bearer ${API_KEY}` },
    }).then((res) => res.json());
    const { priceRanges } = result.data;

    const sortedDates = sortByDate(Object.keys(priceRanges));
    const labels = sortedDates.map((date) =>
      formatDate(date.split("-").join("/"))
    );

    const data = {
      labels,
      datasets: [
        // Ranges
        ...["all", "competition", "show"].map((label, i) => {
          const labels = {
            show: `${productionName} range`,
            all: "Range for all",
            competition: "Range for the competition",
          };
          return {
            type: "bar",
            label: labels[label],
            data: sortedDates.map((date) => {
              const info = priceRanges[date][label];
              return [info?.low ?? 0, info?.high ?? 0];
            }),
            backgroundColor: `rgba(${COLORS[i]}, .5)`,
            borderColor: `rgba(${COLORS[i]}, .5)`,
            borderWidth: 1,
            datalabels: {
              labels: {
                low: {
                  align: "start",
                  anchor: "start",
                  formatter: ([value, highValue]) => {
                    if (value === highValue) return "";
                    if (!value) return "";
                    // return "$" + value.toFixed(2);
                    return label === "show" ? "$" + Math.round(value) : "";
                  },
                },
                high: {
                  formatter: ([, value]) => {
                    if (!value) return "";
                    // return "$" + value.toFixed(2);
                    return label === "show" ? "$" + Math.round(value) : "";
                  },
                  align: "end",
                  anchor: "end",
                },
              },
            },
          };
        }),

        // Averages
        // ...["all", "competition", "show"].map((label, i) => {
        //   const labels = {
        //     show: `${productionName} average`,
        //     all: "Average Price Listed",
        //     competition: "Average for the competition",
        //   };
        //   return {
        //     type: "line",
        //     label: labels[label],
        //     data: sortedDates.map((date) => {
        //       const info = priceRanges[date][label];
        //       return info?.averageListed || null;
        //     }),
        //     backgroundColor: `rgba(${COLORS[i]}, 1)`,
        //     borderColor: `rgba(${COLORS[i]}, 1)`,
        //     borderWidth: 1,
        //     datalabels: {
        //       formatter: () => "",
        //     },
        //   };
        // }),
      ],
    };

    const config = {
      plugins: [ChartDataLabels],
      type: "bar",
      data,
      options: {
        tension: 0.3,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              font: {
                family: "Verdana",
              },
            },
          },
          datalabels: {
            textAlign: "center",
            font: {
              family: "Verdana",
            },
          },
          title: {
            display: true,
            text: "Prices at TKTS Trailing 7 days",
            position: "top",
            font: { family: "Verdana" },
          },
          tooltip: {
            font: { family: "Verdana" },
            callbacks: {
              title: ([context]) => {
                const date = sortedDates[context.dataIndex];
                return formatDate(date.split("-").join("/"));
              },
              label: (context) => {
                if (Array.isArray(context.raw)) {
                  const [low, high] = context.raw;
                  return `$${parseFloat(low).toFixed(2)} - ${parseFloat(
                    high
                  ).toFixed(2)}`;
                }
                return `$${parseFloat(context.formattedValue).toFixed(2)}`;
              },
            },
          },
        },
        scales: {
          y: {
            ticks: {
              font: { family: "Verdana" },
            },
          },
          x: {
            ticks: {
              font: {
                family: "Verdana",
                weight: labels.map((label) =>
                  [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
                ),
              },
            },
          },
        },
      },
    };

    renderChartToScreen(`primary-show-pod-chart-${productionId}`, config);
  }
});
