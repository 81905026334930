document.addEventListener("turbolinks:load", function() {
  // if information page
  if (!document.getElementById("information")) return;

  document
    .getElementById("information-toggle")
    ?.addEventListener("slider-change", (e) => {
      const standardBoard = document.getElementById("standard-board");
      const showtimeBoard = document.getElementById("showtime-board");
      const priceBoard = document.getElementById("price-board");

      standardBoard.classList.add("hidden");
      showtimeBoard.classList.add("hidden");
      priceBoard.classList.add("hidden");

      switch (e.detail.value) {
        case "type":
          standardBoard.classList.remove("hidden");
          break;
        case "time":
          showtimeBoard.classList.remove("hidden");
          break;
        case "price":
          priceBoard.classList.remove("hidden");
          break;
      }
    });
});
