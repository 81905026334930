
document.addEventListener("turbolinks:load", function() {
  // if homepage
  if (document.getElementById("homepage")) {
    // const button = document.querySelector("#newsletter-more")
    //
    // button.removeEventListener("click", handleMoreClick)
    // button.addEventListener("click", handleMoreClick)
    //
    // function handleMoreClick() {
    //   const container = document.querySelector(".newsletter")
    //
    //   container.classList.toggle("expand")
    //   if (button.textContent === "More") {
    //     button.textContent = "Less";
    //   } else {
    //     button.textContent = "More"
    //   }
    // }
  }
});

