document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll(".radio-slider").forEach(function(component) {
    component.addEventListener("set-value", function(event) {
      const value = event.detail.value;
      component.querySelector(`input[type="radio"][value="${value}"]`)?.click();
    });

    component.querySelectorAll('input[type="radio"]').forEach(function(input) {
      input.addEventListener("input", function(event) {
        component.dispatchEvent(
          new CustomEvent("slider-change", {
            detail: { value: event.target.value },
          }),
        );
      });
    });
  });
});
